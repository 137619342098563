<template>
    <el-popover placement="bottom" width="184" popper-class="br-8">
        <p v-for="data in 3" :key="data" class="text-general2 f-17 cr-pointer"> Aguachica </p>
        <hr class="my-2 border" />
        <p class="text-center">
            <span class="text-general2 f-17">¿No es tú país?</span> <br />
            <span class="text-red f-17">Cámbialo acá</span>
        </p>
        <div class="d-flex countrys cr-pointer position-relative" slot="reference">
            <span class="f-14 text-general">
                Bucaramanga
            </span>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/200px-Flag_of_Colombia.svg.png" class="obj-cover mx-2 br-4" width="45" height="22" />
            <i class="icon-down-open f-14 text-general" />
        </div>
    </el-popover>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    methods: {}
}
</script>
<style lang="scss" scoped>

.countrys{
    position: relative;
    .options-countrys{
        border: 1px solid #DBDBDB;
        position: absolute;
        width: 184px;
        left: -19px;
        top: 38px;
        border: 1px solid #DBDBDB;
        border-radius: 8px;
    }
}

@media (min-width: 300px) and (max-width: 560px) {
    .countrys{
        span{ display: none !important; }
    }    
}

</style>